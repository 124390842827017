import { QueryClient, dehydrate } from "@tanstack/react-query"
import { ThemeProvider } from "next-themes"
import Head from "next/head"
import { useRouter } from "next/router"
import { useMemo } from "react"

import { getLocaleFromAcceptLanguage } from "@spatialsys/js/util/locale"
import { convertQueryParamToString } from "@spatialsys/js/util/query-params"
import { GET_EXTERNAL_GAMES_QUERY_KEY } from "@spatialsys/react/query-hooks/external-games"
import { GET_FEATURE_FLAGS_QUERY_KEY } from "@spatialsys/react/query-hooks/feature-flags"
import { GET_CATEGORIES_MENU_QUERY_KEY } from "@spatialsys/react/query-hooks/menu"
import { SpacesQueryKeys } from "@spatialsys/react/query-hooks/spaces"
import { JsonLdInHead } from "@spatialsys/react/util/json-ld-in-head"
import { queryClientServerOptions } from "@spatialsys/web/app-context"
import { SpatialCanvasState } from "@spatialsys/web/app-state"
import { HideMobileBanner } from "@spatialsys/web/core/js/components/mobile-banner/mobile-banner"
import { CanonicalUrlTag } from "@spatialsys/web/core/js/components/seo/seo"
import { homePageJsonLd } from "@spatialsys/web/core/js/json-ld/home-page-json-ld"
import { Homepage } from "@spatialsys/web/homepage/homepage"
import { getSapiClient } from "@spatialsys/web/swag/ssr/api-clients"
import { SwagGetServerSideProps, withContext } from "@spatialsys/web/swag/ssr/context"

type IndexPageProps = {
  userAgent: string
  spatialUid: string
  canvasMode: SpatialCanvasState["mode"]
  locale: string
}

const ssr: SwagGetServerSideProps<IndexPageProps> = async (context) => {
  const { auth, spatialUid, query } = context
  const forwarded = context.req.headers["x-forwarded-for"]
  const clientIp = forwarded ? (forwarded as string) : context.req.socket.remoteAddress

  const locale =
    context.req.cookies["NEXT_LOCALE"] || getLocaleFromAcceptLanguage(context.req.headers["accept-language"])

  let canvasMode: SpatialCanvasState["mode"] = "closed"

  const sapiClient = getSapiClient({ spatialUid, authToken: auth.authSession?.idToken, clientIp })

  const queryClient = new QueryClient(queryClientServerOptions)
  const userAgent = context.req.headers["user-agent"] ?? ""

  const results = await Promise.allSettled([
    queryClient.prefetchQuery({
      queryKey: [SpacesQueryKeys.GetFeaturedCarousel],
      queryFn: sapiClient.spaces.spaces.getFeaturedCarousel,
    }),
    queryClient.prefetchQuery({
      queryKey: [SpacesQueryKeys.GetFeed, userAgent],
      queryFn: () =>
        // Forwarding the user-agent because the response can be different for mobile vs desktop
        sapiClient.v2.getFeed({ userAgent }),
    }),
    queryClient.fetchQuery({
      queryKey: GET_FEATURE_FLAGS_QUERY_KEY,
      queryFn: () => sapiClient.featureFlags.get(),
    }),
    queryClient.fetchQuery({
      queryKey: [GET_CATEGORIES_MENU_QUERY_KEY],
      queryFn: sapiClient.menu.getCategories,
    }),
    queryClient.prefetchInfiniteQuery({
      queryKey: [GET_EXTERNAL_GAMES_QUERY_KEY, 20],
      queryFn: () => sapiClient.externalGames.getGames({ count: 20 }),
    }),
  ] as const)

  if (results[2].status === "fulfilled") {
    const featureFlags = results[2].value.featureFlags
    if (featureFlags.jumpCut) {
      canvasMode = "featured"
    }
  }

  return {
    props: {
      // FIXME: remove this gross hack after upgrading react-query v5
      // See https://github.com/TanStack/query/issues/1458#issuecomment-1532864933
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      ...auth,
      immediatelyOpenLogin: query.login === "true",
      userAgent,
      spatialUid,
      canvasMode,
      locale,
    },
  }
}

export const getServerSideProps = withContext(ssr)

export default function IndexPage(props: IndexPageProps) {
  const router = useRouter()
  const themeParam = convertQueryParamToString(router.query.theme)
  const theme = useMemo(() => {
    if (themeParam === "light") return "light"
    if (themeParam === "dark") return "dark"
    return "dark"
  }, [themeParam])

  const locale = useMemo(() => props.locale ?? "en-US", [props.locale])

  return (
    <ThemeProvider attribute="class" forcedTheme={theme}>
      <Head>{CanonicalUrlTag("/")}</Head>
      <JsonLdInHead jsonLd={homePageJsonLd} />
      <Homepage userAgent={props.userAgent} locale={locale} />
      <HideMobileBanner />
    </ThemeProvider>
  )
}
